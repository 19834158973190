@import 'styles/layers.scss';

@layer component {
  .content {
    width: 740px;
    overflow-y: auto;
    padding-right: 20px;
  }
  
  .title {
    font-size: 22px;
    max-width: 619px;
    margin-bottom: 40px;
  }
  
  .wrap {
    margin-bottom: 20px;
    position: relative;
    column-gap: 20px;
    width: 100%;
    &::before {
      content: "";
      position: absolute;
      height: 1px;
      width: 100%;
      background-color: var(--white);
      opacity: 0.1;
      top: 32px;
    }
  }

  .documentation-wrapper {
    position: relative;
    width: 100%;
  }

  .documentation {
    position: absolute;
    right: 2px;
    top: 2px;
  }
}