@import 'styles/layers.scss';

@layer component {
  .about {
    :global {
      a, :active, a:visited {
        color: var(--blue4);
      }
      color: var(--white);
    }
  }
}
